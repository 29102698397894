// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at https://mozilla.org/MPL/2.0/.

import { ServiceException } from "./meta.js";

/**
 * All resource accessors and schema elements for the Consumer portal.
 *
 * The service interfaces restrict the visible portion of this portal based
 * on the current context. However, dealing with the entirety becomes
 * unavoidable when performing open ended navigation or deep linking.
 */
export default class ConsumerService {

    #handler;

    #entrypoint;

    #options;

    /**
     * The public constructor, which acts as a deep link into the remote API.
     *
     * The service can be cast to one of the relevant service interfaces, or
     * used directly to access all local resources.
     *
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {Object} options - All currently available links
     */
    constructor(handler, entrypoint, options) {
        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#options = options;
    }

    /**
     * Attempt to save the navigation state to a clean object.
     *
     * @returns {?Object} The navigation state, or null if the service is incorrectly configured.
     */
    save() {
        return this.#options ? {...this.#options} : {};
    }

    /**
     * A flag indicating the module resource is available.
     *
     * @type {boolean}
     */
    get hasModule() {
        return this.#options
            && this.#options.module
            && this.#options.module.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the module resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ModuleUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asModuleUri(signal = null) {
        if (this.#handler != null && this.hasModule) {
            return new ModuleUri(this.#options.module, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The module endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the module resource url.
     *
     * See {@link ModuleUri.createRelativeUri} or
     * {@link ModuleUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ModuleUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toModuleUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ModuleUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The module endpoint is unavailable");
    }

    /**
     * A flag indicating the repository resource is available.
     *
     * @type {boolean}
     */
    get hasRepository() {
        return this.#options
            && this.#options.repository
            && this.#options.repository.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the repository resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {RepositoryUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asRepositoryUri(signal = null) {
        if (this.#handler != null && this.hasRepository) {
            return new RepositoryUri(this.#options.repository, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The repository endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the repository resource url.
     *
     * See {@link RepositoryUri.createRelativeUri} or
     * {@link RepositoryUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {RepositoryUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toRepositoryUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new RepositoryUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The repository endpoint is unavailable");
    }

    /**
     * A flag indicating the generate resource is available.
     *
     * @type {boolean}
     */
    get hasGenerate() {
        return this.#options
            && this.#options.generate
            && this.#options.generate.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the generate resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {GenerateUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asGenerateUri(signal = null) {
        if (this.#handler != null && this.hasGenerate) {
            return new GenerateUri(this.#options.generate, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The generate endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the generate resource url.
     *
     * See {@link GenerateUri.createRelativeUri} or
     * {@link GenerateUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {GenerateUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toGenerateUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new GenerateUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The generate endpoint is unavailable");
    }

    /**
     * A flag indicating the list artifacts resource is available.
     *
     * @type {boolean}
     */
    get hasListArtifacts() {
        return this.#options
            && this.#options.list_artifacts
            && this.#options.list_artifacts.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the list artifacts resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The list artifacts resource has 3 navigations:
     * - From /rows to the release artifact action
     * - From /rows to the clone artifact action
     * - From /rows to the delete artifact action
     *
     * @returns {ListArtifactsUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asListArtifactsUri(signal = null) {
        if (this.#handler != null && this.hasListArtifacts) {
            return new ListArtifactsUri(this.#options.list_artifacts, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list artifacts endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the list artifacts resource url.
     *
     * See {@link ListArtifactsUri.createRelativeUri} or
     * {@link ListArtifactsUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ListArtifactsUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toListArtifactsUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ListArtifactsUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list artifacts endpoint is unavailable");
    }

    /**
     * A flag indicating the list modules resource is available.
     *
     * @type {boolean}
     */
    get hasListModules() {
        return this.#options
            && this.#options.list_modules
            && this.#options.list_modules.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the list modules resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The list modules resource has 1 navigation:
     * - From /rows to the module context
     *
     * @returns {ListModulesUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asListModulesUri(signal = null) {
        if (this.#handler != null && this.hasListModules) {
            return new ListModulesUri(this.#options.list_modules, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list modules endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the list modules resource url.
     *
     * See {@link ListModulesUri.createRelativeUri} or
     * {@link ListModulesUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ListModulesUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toListModulesUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ListModulesUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list modules endpoint is unavailable");
    }

    /**
     * A flag indicating the list published resource is available.
     *
     * @type {boolean}
     */
    get hasListPublished() {
        return this.#options
            && this.#options.list_published
            && this.#options.list_published.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the list published resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The list published resource has 1 navigation:
     * - From /rows to the manage dependency action
     *
     * @returns {ListPublishedUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asListPublishedUri(signal = null) {
        if (this.#handler != null && this.hasListPublished) {
            return new ListPublishedUri(this.#options.list_published, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list published endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the list published resource url.
     *
     * See {@link ListPublishedUri.createRelativeUri} or
     * {@link ListPublishedUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ListPublishedUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toListPublishedUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ListPublishedUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list published endpoint is unavailable");
    }

    /**
     * A flag indicating the list tokens resource is available.
     *
     * @type {boolean}
     */
    get hasListTokens() {
        return this.#options
            && this.#options.list_tokens
            && this.#options.list_tokens.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the list tokens resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ListTokensUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asListTokensUri(signal = null) {
        if (this.#handler != null && this.hasListTokens) {
            return new ListTokensUri(this.#options.list_tokens, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list tokens endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the list tokens resource url.
     *
     * See {@link ListTokensUri.createRelativeUri} or
     * {@link ListTokensUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ListTokensUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toListTokensUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ListTokensUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The list tokens endpoint is unavailable");
    }

    /**
     * A flag indicating the parse resource is available.
     *
     * @type {boolean}
     */
    get hasParse() {
        return this.#options
            && this.#options.parse
            && this.#options.parse.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the parse resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ParseUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asParseUri(signal = null) {
        if (this.#handler != null && this.hasParse) {
            return new ParseUri(this.#options.parse, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The parse endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the parse resource url.
     *
     * See {@link ParseUri.createRelativeUri} or
     * {@link ParseUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ParseUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toParseUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ParseUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The parse endpoint is unavailable");
    }

    /**
     * A flag indicating the public model resource is available.
     *
     * @type {boolean}
     */
    get hasPublicModel() {
        return this.#options
            && this.#options.public_model
            && this.#options.public_model.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the public model resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {PublicModelUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asPublicModelUri(signal = null) {
        if (this.#handler != null && this.hasPublicModel) {
            return new PublicModelUri(this.#options.public_model, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The public model endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the public model resource url.
     *
     * See {@link PublicModelUri.createRelativeUri} or
     * {@link PublicModelUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {PublicModelUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toPublicModelUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new PublicModelUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The public model endpoint is unavailable");
    }

    /**
     * A flag indicating the session resource is available.
     *
     * @type {boolean}
     */
    get hasSession() {
        return this.#options
            && this.#options.session
            && this.#options.session.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the session resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The session resource has 3 navigations:
     * - From / to the list modules view
     * - From / to the public model view
     * - From / to the setup project action
     *
     * @returns {SessionUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asSessionUri(signal = null) {
        if (this.#handler != null && this.hasSession) {
            return new SessionUri(this.#options.session, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The session endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the session resource url.
     *
     * See {@link SessionUri.createRelativeUri} or
     * {@link SessionUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {SessionUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toSessionUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new SessionUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The session endpoint is unavailable");
    }

    /**
     * A flag indicating the statistics resource is available.
     *
     * @type {boolean}
     */
    get hasStatistics() {
        return this.#options
            && this.#options.statistics
            && this.#options.statistics.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the statistics resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {StatisticsUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asStatisticsUri(signal = null) {
        if (this.#handler != null && this.hasStatistics) {
            return new StatisticsUri(this.#options.statistics, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The statistics endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the statistics resource url.
     *
     * See {@link StatisticsUri.createRelativeUri} or
     * {@link StatisticsUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {StatisticsUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toStatisticsUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new StatisticsUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The statistics endpoint is unavailable");
    }

    /**
     * A flag indicating the tag resource is available.
     *
     * @type {boolean}
     */
    get hasTag() {
        return this.#options
            && this.#options.tag
            && this.#options.tag.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the tag resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The tag resource has 2 navigations:
     * - From / to the module context
     * - From / to the statistics view
     *
     * @returns {TagUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asTagUri(signal = null) {
        if (this.#handler != null && this.hasTag) {
            return new TagUri(this.#options.tag, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The tag endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the tag resource url.
     *
     * See {@link TagUri.createRelativeUri} or
     * {@link TagUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {TagUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toTagUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new TagUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The tag endpoint is unavailable");
    }

    /**
     * A flag indicating the clone artifact resource is available.
     *
     * @type {boolean}
     */
    get hasCloneArtifact() {
        return this.#options
            && this.#options.clone_artifact
            && this.#options.clone_artifact.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the clone artifact resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The clone artifact resource has 18 navigations:
     * - From / to the module context
     * - From / to the clone artifact action
     * - From / to the compile action
     * - From / to the create action
     * - From / to the create token action
     * - From / to the delete artifact action
     * - From / to the edit action
     * - From / to the expire token action
     * - From / to the manage dependency action
     * - From / to the move action
     * - From / to the release artifact action
     * - From / to the generate view
     * - From / to the list artifacts view
     * - From / to the list published view
     * - From / to the list tokens view
     * - From / to the parse view
     * - From / to the statistics view
     * - From / to the tag view
     *
     * @returns {CloneArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asCloneArtifactUri(signal = null) {
        if (this.#handler != null && this.hasCloneArtifact) {
            return new CloneArtifactUri(this.#options.clone_artifact, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The clone artifact endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the clone artifact resource url.
     *
     * See {@link CloneArtifactUri.createRelativeUri} or
     * {@link CloneArtifactUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CloneArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toCloneArtifactUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new CloneArtifactUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The clone artifact endpoint is unavailable");
    }

    /**
     * A flag indicating the compile resource is available.
     *
     * @type {boolean}
     */
    get hasCompile() {
        return this.#options
            && this.#options.compile
            && this.#options.compile.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the compile resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CompileUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asCompileUri(signal = null) {
        if (this.#handler != null && this.hasCompile) {
            return new CompileUri(this.#options.compile, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The compile endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the compile resource url.
     *
     * See {@link CompileUri.createRelativeUri} or
     * {@link CompileUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CompileUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toCompileUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new CompileUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The compile endpoint is unavailable");
    }

    /**
     * A flag indicating the create resource is available.
     *
     * @type {boolean}
     */
    get hasCreate() {
        return this.#options
            && this.#options.create
            && this.#options.create.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the create resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CreateUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asCreateUri(signal = null) {
        if (this.#handler != null && this.hasCreate) {
            return new CreateUri(this.#options.create, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The create endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the create resource url.
     *
     * See {@link CreateUri.createRelativeUri} or
     * {@link CreateUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CreateUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toCreateUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new CreateUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The create endpoint is unavailable");
    }

    /**
     * A flag indicating the create token resource is available.
     *
     * @type {boolean}
     */
    get hasCreateToken() {
        return this.#options
            && this.#options.create_token
            && this.#options.create_token.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the create token resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CreateTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asCreateTokenUri(signal = null) {
        if (this.#handler != null && this.hasCreateToken) {
            return new CreateTokenUri(this.#options.create_token, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The create token endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the create token resource url.
     *
     * See {@link CreateTokenUri.createRelativeUri} or
     * {@link CreateTokenUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {CreateTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toCreateTokenUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new CreateTokenUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The create token endpoint is unavailable");
    }

    /**
     * A flag indicating the delete artifact resource is available.
     *
     * @type {boolean}
     */
    get hasDeleteArtifact() {
        return this.#options
            && this.#options.delete_artifact
            && this.#options.delete_artifact.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the delete artifact resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The delete artifact resource has 18 navigations:
     * - From / to the module context
     * - From / to the clone artifact action
     * - From / to the compile action
     * - From / to the create action
     * - From / to the create token action
     * - From / to the delete artifact action
     * - From / to the edit action
     * - From / to the expire token action
     * - From / to the manage dependency action
     * - From / to the move action
     * - From / to the release artifact action
     * - From / to the generate view
     * - From / to the list artifacts view
     * - From / to the list published view
     * - From / to the list tokens view
     * - From / to the parse view
     * - From / to the statistics view
     * - From / to the tag view
     *
     * @returns {DeleteArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asDeleteArtifactUri(signal = null) {
        if (this.#handler != null && this.hasDeleteArtifact) {
            return new DeleteArtifactUri(this.#options.delete_artifact, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The delete artifact endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the delete artifact resource url.
     *
     * See {@link DeleteArtifactUri.createRelativeUri} or
     * {@link DeleteArtifactUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {DeleteArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toDeleteArtifactUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new DeleteArtifactUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The delete artifact endpoint is unavailable");
    }

    /**
     * A flag indicating the edit resource is available.
     *
     * @type {boolean}
     */
    get hasEdit() {
        return this.#options
            && this.#options.edit
            && this.#options.edit.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the edit resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {EditUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asEditUri(signal = null) {
        if (this.#handler != null && this.hasEdit) {
            return new EditUri(this.#options.edit, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The edit endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the edit resource url.
     *
     * See {@link EditUri.createRelativeUri} or
     * {@link EditUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {EditUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toEditUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new EditUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The edit endpoint is unavailable");
    }

    /**
     * A flag indicating the expire token resource is available.
     *
     * @type {boolean}
     */
    get hasExpireToken() {
        return this.#options
            && this.#options.expire_token
            && this.#options.expire_token.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the expire token resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ExpireTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asExpireTokenUri(signal = null) {
        if (this.#handler != null && this.hasExpireToken) {
            return new ExpireTokenUri(this.#options.expire_token, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The expire token endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the expire token resource url.
     *
     * See {@link ExpireTokenUri.createRelativeUri} or
     * {@link ExpireTokenUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ExpireTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toExpireTokenUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ExpireTokenUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The expire token endpoint is unavailable");
    }

    /**
     * A flag indicating the manage dependency resource is available.
     *
     * @type {boolean}
     */
    get hasManageDependency() {
        return this.#options
            && this.#options.manage_dependency
            && this.#options.manage_dependency.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the manage dependency resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The manage dependency resource has 18 navigations:
     * - From / to the module context
     * - From / to the clone artifact action
     * - From / to the compile action
     * - From / to the create action
     * - From / to the create token action
     * - From / to the delete artifact action
     * - From / to the edit action
     * - From / to the expire token action
     * - From / to the manage dependency action
     * - From / to the move action
     * - From / to the release artifact action
     * - From / to the generate view
     * - From / to the list artifacts view
     * - From / to the list published view
     * - From / to the list tokens view
     * - From / to the parse view
     * - From / to the statistics view
     * - From / to the tag view
     *
     * @returns {ManageDependencyUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asManageDependencyUri(signal = null) {
        if (this.#handler != null && this.hasManageDependency) {
            return new ManageDependencyUri(this.#options.manage_dependency, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The manage dependency endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the manage dependency resource url.
     *
     * See {@link ManageDependencyUri.createRelativeUri} or
     * {@link ManageDependencyUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ManageDependencyUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toManageDependencyUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ManageDependencyUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The manage dependency endpoint is unavailable");
    }

    /**
     * A flag indicating the move resource is available.
     *
     * @type {boolean}
     */
    get hasMove() {
        return this.#options
            && this.#options.move
            && this.#options.move.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the move resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {MoveUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asMoveUri(signal = null) {
        if (this.#handler != null && this.hasMove) {
            return new MoveUri(this.#options.move, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The move endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the move resource url.
     *
     * See {@link MoveUri.createRelativeUri} or
     * {@link MoveUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {MoveUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toMoveUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new MoveUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The move endpoint is unavailable");
    }

    /**
     * A flag indicating the release artifact resource is available.
     *
     * @type {boolean}
     */
    get hasReleaseArtifact() {
        return this.#options
            && this.#options.release_artifact
            && this.#options.release_artifact.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the release artifact resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The release artifact resource has 18 navigations:
     * - From / to the module context
     * - From / to the clone artifact action
     * - From / to the compile action
     * - From / to the create action
     * - From / to the create token action
     * - From / to the delete artifact action
     * - From / to the edit action
     * - From / to the expire token action
     * - From / to the manage dependency action
     * - From / to the move action
     * - From / to the release artifact action
     * - From / to the generate view
     * - From / to the list artifacts view
     * - From / to the list published view
     * - From / to the list tokens view
     * - From / to the parse view
     * - From / to the statistics view
     * - From / to the tag view
     *
     * @returns {ReleaseArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asReleaseArtifactUri(signal = null) {
        if (this.#handler != null && this.hasReleaseArtifact) {
            return new ReleaseArtifactUri(this.#options.release_artifact, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The release artifact endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the release artifact resource url.
     *
     * See {@link ReleaseArtifactUri.createRelativeUri} or
     * {@link ReleaseArtifactUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ReleaseArtifactUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toReleaseArtifactUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ReleaseArtifactUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The release artifact endpoint is unavailable");
    }

    /**
     * A flag indicating the setup project resource is available.
     *
     * @type {boolean}
     */
    get hasSetupProject() {
        return this.#options
            && this.#options.setup_project
            && this.#options.setup_project.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the setup project resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * The setup project resource has 18 navigations:
     * - From / to the module context
     * - From / to the clone artifact action
     * - From / to the compile action
     * - From / to the create action
     * - From / to the create token action
     * - From / to the delete artifact action
     * - From / to the edit action
     * - From / to the expire token action
     * - From / to the manage dependency action
     * - From / to the move action
     * - From / to the release artifact action
     * - From / to the generate view
     * - From / to the list artifacts view
     * - From / to the list published view
     * - From / to the list tokens view
     * - From / to the parse view
     * - From / to the statistics view
     * - From / to the tag view
     *
     * @returns {SetupProjectUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asSetupProjectUri(signal = null) {
        if (this.#handler != null && this.hasSetupProject) {
            return new SetupProjectUri(this.#options.setup_project, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The setup project endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the setup project resource url.
     *
     * See {@link SetupProjectUri.createRelativeUri} or
     * {@link SetupProjectUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {SetupProjectUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toSetupProjectUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new SetupProjectUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The setup project endpoint is unavailable");
    }

    /**
     * A flag indicating the validate token resource is available.
     *
     * @type {boolean}
     */
    get hasValidateToken() {
        return this.#options
            && this.#options.validate_token
            && this.#options.validate_token.startsWith(this.#entrypoint);
    }

    /**
     * Start building an API request from the validate token resource url.
     *
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ValidateTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    asValidateTokenUri(signal = null) {
        if (this.#handler != null && this.hasValidateToken) {
            return new ValidateTokenUri(this.#options.validate_token, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The validate token endpoint is unavailable");
    }

    /**
     * Create a re-entrant request to the validate token resource url.
     *
     * See {@link ValidateTokenUri.createRelativeUri} or
     * {@link ValidateTokenUri.asRelativeUri} for the
     * corresponding constructors
     *
     * @param {String} [uri] - A url fragment for the request, if the link should be ignored
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {ValidateTokenUri}
     * @throws {Error} Thrown when the url is unavailable.
     */
    toValidateTokenUri(uri, signal = null) {
        if (this.#handler != null) {
            var r = new URL(uri, this.#entrypoint).toString();
            return new ValidateTokenUri(r, this.#handler, this.#entrypoint, signal);
        } else throw new Error("The validate token endpoint is unavailable");
    }

    /**
     * Open-ended navigation within the service group.
     *
     * See the source URI and the object itself for documentation on
     * where each entity can link.
     *
     * @param {Object} [src] - an object
     *
     * @returns {ConsumerService}
     */
    from(src) {
        return new ConsumerService(this.#handler, this.#entrypoint, src?.options);
    }

    /**
     * A utility method for downloading attachments.
     *
     * @param {string} uri - a uri
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<Blob>}
     */
    getAttachmentAsync(uri, signal = null) {
        return this.#handler.getAttachmentAsync(uri, signal);
    }

    /**
     * A utility method for downloading resources directly.
     * Fetch JSON data from an API endpoint.
     *
     * @param {string} uri - a uri
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<Object>}
     */
    getFromJsonAsync(uri, signal = null) {
        return this.#handler.getFromJsonAsync(uri, signal);
    }

    /**
     * A utility method for scrolling through a resource collection.
     *
     * @param {string} uri - a uri
     * @param {AbortSignal} [signal] - an abort signal
     * @param {boolean} [lazy=false] - a flag indicating the next page should be lazy-loaded
     *
     * @returns {Promise<ResultSet>}
     */
    scrollFromJsonAsync(uri, signal = null, lazy = false) {
        return this.#handler.scrollFromJsonAsync(uri, signal, lazy);
    }

    /**
     * The root entrypoint into the service group.
     *
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} uri - The uri for the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     *
     * @returns {?ConsumerService} The service.
     */
    static async GetEntryPointAsync(handler, uri, signal = null) {
        let headers = new Headers();
        headers.append("Accept", "application/json");
        let request = new Request(uri, { headers });
        let response = await handler.sendAsync(request, signal);
        if (!response.ok) {
            throw await ServiceException.CreateAsync(response);
        }
        let src = await response.json();
        let origin = response.url.endsWith('/') ? response.url : response.url + '/';
        return new ConsumerService(handler, origin, src?.options);
    }

}

/**
 * A mutable intermediate form for a requested module context
 */
class ModuleUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the module context.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Information about a wiki-hosted project.
     *
     * @returns {Promise}
     */
    async getAsync() {
        let r = await this.#handler.getFromJsonAsync(this, this.#signal);
        if (r == null) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The module service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return r;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }`;
    }

}

/**
 * A mutable intermediate form for a requested repository context
 */
class RepositoryUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the repository context.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * A storage location for project designs.
     *
     * @returns {Promise}
     */
    async getAsync() {
        let r = await this.#handler.getFromJsonAsync(this, this.#signal);
        if (r == null) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The repository service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return r;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki) {
        return `repository/${ encodeURIComponent(wiki) }`;
    }

}

/**
 * A mutable intermediate form for a requested generate view
 */
class GenerateUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the generate view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param token - The token.
     * @param branch - The branch, if a specific target is needed.
     * @param version - The version, if a specific target is needed.
     * @param name - A replacement project name, if the project name needs to be overridden.
     * @param flag - An optional flag, if the generation target is to be modified.
     *
     * @returns this
     */
    withParams(
         token,
         branch,
         version = null,
         name = null,
         flag = null) {
        this.searchParams.delete("token");
        this.searchParams.set("token", encodeURIComponent(token));
        this.searchParams.delete("branch");
        this.searchParams.set("branch", encodeURIComponent(branch));
        this.searchParams.delete("version");
        if (version != null) {
            this.searchParams.set("version", version);
        }
        this.searchParams.delete("name");
        if (name != null) {
            this.searchParams.set("name", encodeURIComponent(name));
        }
        this.searchParams.delete("flag");
        if (flag != null) {
            this.searchParams.set("flag", encodeURIComponent(flag));
        }
        return this;
    }

    /**
     * Generate a project with a preset configuration using an
     * access token.
     *
     * @returns {Promise}
     */
    getAsync() {
        return this.#handler.getAttachmentAsync(this, this.#signal);
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path,
            token,
            branch,
            version = null,
            name = null,
            flag = null) {
        var p = `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/generate`;
        var q = new URLSearchParams();
        q.set("token", encodeURIComponent(token));
        q.set("branch", encodeURIComponent(branch));
        if (version != null) {
            q.set("version", version);
        }
        if (name != null) {
            q.set("name", encodeURIComponent(name));
        }
        if (flag != null) {
            q.set("flag", encodeURIComponent(flag));
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested list artifacts view
 */
class ListArtifactsUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the list artifacts view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Search through the hosted artifacts.
     *
     * @param {Object} [options] - A query options object.
     *
     * The list artifacts resource has 3 navigations
     * - From /rows to the release artifact action
     * - From /rows to the clone artifact action
     * - From /rows to the delete artifact action
     *
     */
    getAsync(options = null) {
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, false);
        }
    }

    /**
     * Search through the hosted artifacts.
     *
     * This method provides lazy pagination.
     *
     * @param {string} [query] - The query portion of the link.
     *
     * The list artifacts resource has 3 navigations
     * - From /rows to the release artifact action
     * - From /rows to the clone artifact action
     * - From /rows to the delete artifact action
     *
     */
    pageAsync(query = null, options = null) {
        this.search = query ?? "";
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, true);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/list_artifacts`;
    }

}

/**
 * A mutable intermediate form for a requested list modules view
 */
class ListModulesUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the list modules view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param search - A search string.
     *
     * @returns this
     */
    withParams(
         search = null) {
        this.searchParams.delete("search");
        if (search != null) {
            this.searchParams.set("search", encodeURIComponent(search));
        }
        return this;
    }

    /**
     * Search through the hosted projects.
     *
     * @param {Object} [options] - A query options object.
     *
     * The list modules resource has 1 navigation
     * - From /rows to the module context
     *
     */
    getAsync(options = null) {
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, false);
        }
    }

    /**
     * Search through the hosted projects.
     *
     * This method provides lazy pagination.
     *
     * @param {string} [query] - The query portion of the link.
     *
     * The list modules resource has 1 navigation
     * - From /rows to the module context
     *
     */
    pageAsync(query = null, options = null) {
        this.search = query ?? "";
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, true);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            search = null) {
        var p = `repository/${ encodeURIComponent(wiki) }/list_modules`;
        var q = new URLSearchParams();
        if (search != null) {
            q.set("search", encodeURIComponent(search));
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested list published view
 */
class ListPublishedUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the list published view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param search - A search string.
     *
     * @returns this
     */
    withParams(
         search = null) {
        this.searchParams.delete("search");
        if (search != null) {
            this.searchParams.set("search", encodeURIComponent(search));
        }
        return this;
    }

    /**
     * Search through the published artifacts.
     *
     * @param {Object} [options] - A query options object.
     *
     * The list published resource has 1 navigation
     * - From /rows to the manage dependency action
     *
     */
    getAsync(options = null) {
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, false);
        }
    }

    /**
     * Search through the published artifacts.
     *
     * This method provides lazy pagination.
     *
     * @param {string} [query] - The query portion of the link.
     *
     * The list published resource has 1 navigation
     * - From /rows to the manage dependency action
     *
     */
    pageAsync(query = null, options = null) {
        this.search = query ?? "";
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, true);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path,
            search = null) {
        var p = `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/list_published`;
        var q = new URLSearchParams();
        if (search != null) {
            q.set("search", encodeURIComponent(search));
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested list tokens view
 */
class ListTokensUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the list tokens view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * List all generation tokens for the module.
     *
     * @param {Object} [options] - A query options object.
     *
     */
    getAsync(options = null) {
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, false);
        }
    }

    /**
     * List all generation tokens for the module.
     *
     * This method provides lazy pagination.
     *
     * @param {string} [query] - The query portion of the link.
     *
     */
    pageAsync(query = null, options = null) {
        this.search = query ?? "";
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, true);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/list_tokens`;
    }

}

/**
 * A mutable intermediate form for a requested parse view
 */
class ParseUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the parse view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param docids - A comma-separated list of stable document identifiers in the external storage application.
     * @param fragments - A comma-separated list of  document fragments. The document fragments should only be used when no stable document identifier exists.
     *
     * @returns this
     */
    withParams(
         docids = null,
         fragments = null) {
        this.searchParams.delete("docids");
        if (docids != null) {
            this.searchParams.set("docids", encodeURIComponent(docids));
        }
        this.searchParams.delete("fragments");
        if (fragments != null) {
            this.searchParams.set("fragments", encodeURIComponent(fragments));
        }
        return this;
    }

    /**
     * Parse a collection of documents that contain part of the
     * specification for this module.
     *
     * @param {Object} [options] - A query options object.
     *
     */
    getAsync(options = null) {
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, false);
        }
    }

    /**
     * Parse a collection of documents that contain part of the
     * specification for this module.
     *
     * This method provides lazy pagination.
     *
     * @param {string} [query] - The query portion of the link.
     *
     */
    pageAsync(query = null, options = null) {
        this.search = query ?? "";
        if (options) {
            const { limit, lazy } = options;
            if (limit) {
                this.searchParams.set("$limit", limit);
            }
            return this.#handler.scrollFromJsonAsync(this, this.#signal, lazy);
        } else {
            return this.#handler.scrollFromJsonAsync(this, this.#signal, true);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path,
            docids = null,
            fragments = null) {
        var p = `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/parse`;
        var q = new URLSearchParams();
        if (docids != null) {
            q.set("docids", encodeURIComponent(docids));
        }
        if (fragments != null) {
            q.set("fragments", encodeURIComponent(fragments));
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested public model view
 */
class PublicModelUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the public model view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param wiki - The wiki identifier.
     * @param path - The module path.
     *
     * @returns this
     */
    withParams(
         wiki,
         path) {
        this.searchParams.delete("wiki");
        this.searchParams.set("wiki", encodeURIComponent(wiki));
        this.searchParams.delete("path");
        this.searchParams.set("path", encodeURIComponent(path));
        return this;
    }

    /**
     * Fetch the current state of a publicly available model.
     *
     * @param {Object} [options] - A query options object.
     *
     */
    getAsync() {
        return this.#handler.getFromJsonAsync(this, this.#signal);
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path) {
        var p = "public_model";
        var q = new URLSearchParams();
        q.set("wiki", encodeURIComponent(wiki));
        q.set("path", encodeURIComponent(path));
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested session view
 */
class SessionUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the session view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * The login session.
     *
     * @param {Object} [options] - A query options object.
     *
     * The session resource has 3 navigations
     * - From / to the list modules view
     * - From / to the public model view
     * - From / to the setup project action
     *
     */
    getAsync() {
        return this.#handler.getFromJsonAsync(this, this.#signal);
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri() {
        return "session";
    }

}

/**
 * A mutable intermediate form for a requested statistics view
 */
class StatisticsUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the statistics view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param version - The artifact version.
     * @param minutes - The duration of statistics to fetch.
     *
     * @returns this
     */
    withParams(
         version,
         minutes = null) {
        this.searchParams.delete("version");
        this.searchParams.set("version", version);
        this.searchParams.delete("minutes");
        if (minutes != null) {
            this.searchParams.set("minutes", minutes);
        }
        return this;
    }

    /**
     * Calculate summary statistics for a live artifact.
     *
     * @param {Object} [options] - A query options object.
     *
     */
    getAsync() {
        return this.#handler.getFromJsonAsync(this, this.#signal);
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path,
            version,
            minutes = null) {
        var p = `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/statistics`;
        var q = new URLSearchParams();
        q.set("version", version);
        if (minutes != null) {
            q.set("minutes", minutes);
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested tag view
 */
class TagUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the tag view.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Mutate the request, replacing all query parameters.
     *
     * @param label - The artifact label.
     * @param version - The artifact vesion.
     *
     * @returns this
     */
    withParams(
         label = null,
         version = null) {
        this.searchParams.delete("label");
        if (label != null) {
            this.searchParams.set("label", encodeURIComponent(label));
        }
        this.searchParams.delete("version");
        if (version != null) {
            this.searchParams.set("version", version);
        }
        return this;
    }

    /**
     * Fetch the current state of a tagged model.
     *
     * @param {Object} [options] - A query options object.
     *
     * The tag resource has 2 navigations
     * - From / to the module context
     * - From / to the statistics view
     *
     */
    getAsync() {
        return this.#handler.getFromJsonAsync(this, this.#signal);
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(
            wiki,
            path,
            label = null,
            version = null) {
        var p = `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/tag`;
        var q = new URLSearchParams();
        if (label != null) {
            q.set("label", encodeURIComponent(label));
        }
        if (version != null) {
            q.set("version", version);
        }
        return `${p}?${q}`;
    }

}

/**
 * A mutable intermediate form for a requested clone artifact action
 */
class CloneArtifactUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the clone artifact action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Clone a specific artifact associated with a module.
     *
     * @param artifact_id - The artifact identifier.
     * @param name - The new artifact name.
     * The clone artifact resource has 18 navigations
     * From / to the module context
     * From / to the clone artifact action
     * From / to the compile action
     * From / to the create action
     * From / to the create token action
     * From / to the delete artifact action
     * From / to the edit action
     * From / to the expire token action
     * From / to the manage dependency action
     * From / to the move action
     * From / to the release artifact action
     * From / to the generate view
     * From / to the list artifacts view
     * From / to the list published view
     * From / to the list tokens view
     * From / to the parse view
     * From / to the statistics view
     * From / to the tag view
     */
    async postAsync(
        artifact_id,
        name)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            artifact_id: artifact_id,
            name: name,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The clone artifact service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/clone_artifact`;
    }

}

/**
 * A mutable intermediate form for a requested compile action
 */
class CompileUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the compile action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Compile the current state of the wiki.
     *
     */
    async postAsync()
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The compile service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/compile`;
    }

}

/**
 * A mutable intermediate form for a requested create action
 */
class CreateUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the create action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Create a new page in the module.
     *
     * @param fragment - The target document fragment for the page to reside.
     * @param chunks - All relevant document elements, or chunks, that were extracted from the document.
     */
    async postAsync(
        fragment,
        chunks)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            fragment: fragment,
            chunks: chunks,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The create service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/create`;
    }

}

/**
 * A mutable intermediate form for a requested create token action
 */
class CreateTokenUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the create token action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Create a new API token.
     *
     * @param name - The access token name.
     * @param format - The project format for this token.
     * @param runtime - The project runtime for this token.
     * @param description - Information about the usage of this token.
     */
    async postAsync(
        name,
        format,
        runtime,
        description)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            name: name,
            format: format,
            runtime: runtime,
            description: description,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The create token service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/create_token`;
    }

}

/**
 * A mutable intermediate form for a requested delete artifact action
 */
class DeleteArtifactUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the delete artifact action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Release a specific artifact associated with a module.
     *
     * @param artifact_id - The artifact identifier.
     * The delete artifact resource has 18 navigations
     * From / to the module context
     * From / to the clone artifact action
     * From / to the compile action
     * From / to the create action
     * From / to the create token action
     * From / to the delete artifact action
     * From / to the edit action
     * From / to the expire token action
     * From / to the manage dependency action
     * From / to the move action
     * From / to the release artifact action
     * From / to the generate view
     * From / to the list artifacts view
     * From / to the list published view
     * From / to the list tokens view
     * From / to the parse view
     * From / to the statistics view
     * From / to the tag view
     */
    async postAsync(
        artifact_id)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            artifact_id: artifact_id,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The delete artifact service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/delete_artifact`;
    }

}

/**
 * A mutable intermediate form for a requested edit action
 */
class EditUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the edit action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Edit an existing page in the module.
     *
     * @param docid - The stable document identifier in the external storage application for the page being edited.
     * @param version - The expected version identifier for the page being edited.
     * @param chunks - All relevant document elements, or chunks, that were extracted from the document.
     */
    async postAsync(
        docid,
        version,
        chunks)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            docid: docid,
            version: version,
            chunks: chunks,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The edit service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/edit`;
    }

}

/**
 * A mutable intermediate form for a requested expire token action
 */
class ExpireTokenUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the expire token action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Expire a generation token.
     *
     * @param name - The token name.
     */
    async postAsync(
        name)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            name: name,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/expire_token`;
    }

}

/**
 * A mutable intermediate form for a requested manage dependency action
 */
class ManageDependencyUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the manage dependency action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Add, remove, or up/downgrade a specific dependency.
     *
     * @param requirement - The external project identifier for the requirement.
     * @param version - The referenced version.
     * @param exists - A flag indicating the dependency should exist.
     * The manage dependency resource has 18 navigations
     * From / to the module context
     * From / to the clone artifact action
     * From / to the compile action
     * From / to the create action
     * From / to the create token action
     * From / to the delete artifact action
     * From / to the edit action
     * From / to the expire token action
     * From / to the manage dependency action
     * From / to the move action
     * From / to the release artifact action
     * From / to the generate view
     * From / to the list artifacts view
     * From / to the list published view
     * From / to the list tokens view
     * From / to the parse view
     * From / to the statistics view
     * From / to the tag view
     */
    async postAsync(
        requirement,
        version,
        exists)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            requirement: requirement,
            version: version,
            exists: exists,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The manage dependency service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/manage_dependency`;
    }

}

/**
 * A mutable intermediate form for a requested move action
 */
class MoveUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the move action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Move a page to a specific destination within the model.
     *
     * @param docid - The stable document identifier in the external storage application for the page being moved.
     * @param fragment - The target document fragment for the page to reside.
     */
    async postAsync(
        docid,
        fragment)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            docid: docid,
            fragment: fragment,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The move service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/move`;
    }

}

/**
 * A mutable intermediate form for a requested release artifact action
 */
class ReleaseArtifactUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the release artifact action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Release a specific artifact associated with a module.
     *
     * @param artifact_id - The artifact identifier.
     * @param associations - All data mappings for the release.
     * The release artifact resource has 18 navigations
     * From / to the module context
     * From / to the clone artifact action
     * From / to the compile action
     * From / to the create action
     * From / to the create token action
     * From / to the delete artifact action
     * From / to the edit action
     * From / to the expire token action
     * From / to the manage dependency action
     * From / to the move action
     * From / to the release artifact action
     * From / to the generate view
     * From / to the list artifacts view
     * From / to the list published view
     * From / to the list tokens view
     * From / to the parse view
     * From / to the statistics view
     * From / to the tag view
     */
    async postAsync(
        artifact_id,
        associations)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            artifact_id: artifact_id,
            associations: associations,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The release artifact service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki, path) {
        return `module/${ encodeURIComponent(wiki) }/${ encodeURIComponent(path) }/release_artifact`;
    }

}

/**
 * A mutable intermediate form for a requested setup project action
 */
class SetupProjectUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the setup project action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Configure a new project.
     *
     * @param name - The project name.
     * @param description - A description of the contents of the project.
     * @param key_type - The primary key type for aggregate roots.
     * The setup project resource has 18 navigations
     * From / to the module context
     * From / to the clone artifact action
     * From / to the compile action
     * From / to the create action
     * From / to the create token action
     * From / to the delete artifact action
     * From / to the edit action
     * From / to the expire token action
     * From / to the manage dependency action
     * From / to the move action
     * From / to the release artifact action
     * From / to the generate view
     * From / to the list artifacts view
     * From / to the list published view
     * From / to the list tokens view
     * From / to the parse view
     * From / to the statistics view
     * From / to the tag view
     */
    async postAsync(
        name,
        description,
        key_type = null)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            name: name,
            description: description,
            key_type: key_type,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The setup project service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri(wiki) {
        return `repository/${ encodeURIComponent(wiki) }/setup_project`;
    }

}

/**
 * A mutable intermediate form for a requested validate token action
 */
class ValidateTokenUri extends URL {

    #handler;

    #entrypoint;

    #signal;

    /**
     * The public constructor, which acts as a deep link into the validate token action.
     *
     * @param {string} uri - The raw uri string.
     * @param {meta.HttpHandler} handler - The HTTP request handler
     * @param {string} entrypoint - The url of the service entrypoint
     * @param {AbortSignal} [signal] - The abort signal from the current abort controller
     */
    constructor(uri, handler, entrypoint, signal = null) {
        super(uri, origin);

        this.#handler = handler;
        this.#entrypoint = entrypoint;
        this.#signal = signal;
    }

    /**
     * Create a relative uri with a specified prefix
     *
     * @param {string} [path] - The base path for the uri.
     *
     * @returns {string} - A relative uri string
     */
    asRelativeUri(path = null) {
        return (path ?? "/") + this.toString().replace(origin, '').replace(this.#entrypoint, '');
    }

    /**
     * Validate an API token against an artifact, and return the
     * token metadata.
     *
     * @param wiki - The name of the module wiki.
     * @param path - The module path.
     * @param token - The token.
     * @param branch - The branch, if a specific target is needed.
     * @param version - The version, if a specific target is needed.
     */
    async postAsync(
        wiki,
        path,
        token,
        branch,
        version = null)
    {
        let h = new Headers();
        h.append("Accept", "application/json");
        let c = JSON.stringify({
            wiki: wiki,
            path: path,
            token: token,
            branch: branch,
            version: version,
        });
        h.append("Content-Type", "application/json");
        let r = new Request(this, {
            method: "POST",
            headers: h,
            body: c,
        });

        let o = await this.#handler.sendAsync(r, this.#signal);
        if (!o.ok) {
            throw await ServiceException.CreateAsync(o);
        }
        let p = await o.json();
        if (!p) {
            throw new ServiceException(200, {
                code: "evaluation.3000",
                title: "An unexpected empty response was received from the server",
                detail: "The validate token service returned an empty response, a 404 [NotFound] or a valid payload are expected.",
                errors: [],
            });
        }
        return p;
    }

    /**
     * A utility method for constructing relative URI paths to this resource
     *
     * @returns {string} - A relative uri string
     */
    static createRelativeUri() {
        return "validate_token";
    }

}